<template>
  <section
    class="
      promo__codes-form-wrap
      media-buying-modal
      add-public-modal
      mb_add-post
    "
  >
    <modal :show.sync="active" @close="close">
      <h5 slot="header" class="modal-title" v-if="!form.id">
        {{ $t('mediaBuying.campaign.add_post') }}
      </h5>
      <h5 slot="header" class="modal-title" v-if="form.id">
        <span class="incrementing-id">ID: {{ form.incrementing_id }}</span>
        {{ $t('mediaBuying.campaign.post') }} -
        <span
          :class="
            form.status === 'approved'
              ? 'text-green'
              : form.status === 'declined' || form.status === 'for_rework'
                ? 'text-red'
                : form.status === 'on_review'
                  ? 'text-orange'
                  : 'text-blue'
          "
        >
          {{ form.status && $t(`mediaBuying.posts.status.${form.status}`) }}
        </span>
      </h5>
      <template>
        <div>
          <form>
            <!-- <div v-if="!isPostView" class="bluebox">
              <div class="bluebox__title">
                {{$t('mediaBuying.campaign.unreported_sum')}}
              </div>
              <div class="bluebox__wrap-text">
                <div
                  v-for="(item, key) in balancesUnreported"
                  :key="key"
                  class="bluebox__text"
                >
                  {{ currencyFormatter(key) }} {{ formattedCurrency(item) }}
                </div>
              </div>
            </div> -->
            <div
              v-if="form.id"
              class="comment-overflow"
            >
              <div
                class="comment-wrap__wrap"
                v-for="(comment, index) in comments"
                :key="index"
              >
                <div class="comment-wrap__mess">
                  <div
                    v-if="comment.author_type === 'admin'"
                    class="aff-text-left mb-8"
                  >
                    <span
                      class="
                        comment-wrap__mess-text comment-wrap__mess-text-left
                      "
                    >
                      <span class="date">
                        {{ comment.created_at | formatDate3 }}
                      </span>
                      <span class="comment-wrap__mess-text-row">{{
                        comment.content
                      }}</span>
                      <a
                        v-for="(file, ind) in comment.files"
                        :key="ind"
                        :href="file.download_url"
                        :download="file.filename"
                        target="_blank"
                        class="comment-wrap__mess-files mb-4 mr-4"
                      >
                        <span class="file-name">{{ file.filename }}</span>
                        <i class="fas fa-download pl-4"></i>
                      </a>
                    </span>
                  </div>
                  <div
                    v-if="comment.author_type === 'affiliate'"
                    class="aff-text-right text-right mb-8"
                  >
                    <span
                      :class="{ editable: editIndexComment === index }"
                      class="
                        comment-wrap__mess-text comment-wrap__mess-text-right
                      "
                    >
                      <span class="comment-wrap__content">
                        <span
                          v-if="
                            hideEditAndDelete(comment)
                          "
                          class="comment-wrap__control"
                        >
                          <span class="date">
                            {{ comment.created_at | formatDate3 }}
                          </span>
                          <i
                            v-if="editIndexComment !== index"
                            @click="editMessage(comment, index)"
                            class="fas fa-pen pl-4"
                          ></i>
                          <i
                            v-if="editIndexComment !== index"
                            @click="deleteMessage(comment, index)"
                            class="fas fa-times pl-4"
                          ></i>
                        </span>
                        <span class="comment-wrap__mess-text-row">{{
                          comment.content
                        }}</span>
                        <a
                          v-for="(file, ind) in comment.files"
                          :key="ind"
                          :href="file.download_url"
                          :download="file.filename"
                          target="_blank"
                          class="comment-wrap__mess-files mb-4 mr-4"
                        >
                          <span class="file-name-wrap">
                            <span class="file-name">{{ file.filename }}</span>
                            <i class="fas fa-download pl-4"></i>
                          </span>
                        </a>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="form.id && form.status !== 'draft'"
              class="comment-wrap__inn mt-8"
            >
              <div class="d-flex comment-wrap">
                <div>
                  <div class="form-group select-wrapper m-0 w-100">
                    <textarea
                      v-model="comment"
                      :placeholder="$t('mediaBuying.campaign.comment')"
                      class="form-control form-control-ta"
                    />
                  </div>
                  <button
                    v-if="flagEditComment"
                    class="btn btn-outline-danger btn-sm btn-icon ml-8"
                    @click.prevent="resetEdit"
                  >
                    <i class="fas fa-times text-red"></i>
                  </button>
                  <button
                    class="
                      btn btn-outline-primary
                      d-flex
                      align-items-center
                      justify-content-center
                      btn-sm
                      ml-8
                    "
                    :disabled="isDisabledBtnComment || !comment"
                    @click.prevent="preloadImage"
                  >
                    <img
                      :src="require('@/assets/theme/icon/ok.svg')"
                      alt=""
                      height="12"
                    />
                    <span class="ml-4">{{
                      $t('mediaBuying.campaign.send')
                    }}</span>
                  </button>
                </div>
              </div>
              <div
                class="
                  form-group
                  select-wrapper
                  file-wrap file-wrap-comment
                  mb-0
                  mt-0
                "
              >
                <el-upload
                  :class="{
                    disabled:
                      fileListComment.length ===
                      $store.state.mediaBuying.uploadSettingsData
                        .max_files_per_request,
                  }"
                  class="upload-demo"
                  action=""
                  :on-remove="handleRemoveComment"
                  :on-change="onSuccessComment"
                  :file-list="fileListComment"
                  :auto-upload="false"
                  multiple
                >
                  <button
                    class="btn btn-outline-primary btn-sm btn-icon"
                    @click.prevent
                  >
                    <i class="fas fa-paperclip"></i>
                  </button>
                </el-upload>
                <span
                  v-if="errorFile.length"
                  class="error error-file text-danger text-left mt-2"
                >{{ errorFile[0] }}</span>
              </div>
            </div>
            <div class="select-wrapper mt-0">
              <label>{{ $t('mediaBuying.campaign.type') }}</label>
              <div class="type__radio-wrapper d-flex">
                <el-radio v-model="form.type" label="publication">
                  {{ $t('mediaBuying.campaign.post') }}
                </el-radio>
                <el-radio v-model="form.type" label="cancellation">
                  {{$t('mediaBuying.campaign.write_off')}}
                </el-radio>
              </div>
            </div>
            <div class="wrap-row-column d-flex mb-8">
              <div class="form-group select-wrapper per-wrap">
                <label>{{$t('mediaBuying.campaign.start_of_period')}}</label>
                <i class="far fa-calendar-alt"></i>
                <el-date-picker
                  class="date-picker datepicker-input m-0 p-0 w-100"
                  ref="datepicker"
                  v-model="form.post_period_start"
                  :placeholder="$t('mediaBuying.campaign.specify_period')"
                  type="datetime"
                  format="dd MMM yyyy HH:mm"
                  default-time="00:00:00"
                  @change="error.post_period_start = ''"
                />
                <span
                  v-if="error.post_period_start"
                  class="text-danger text-left mt-2"
                >{{ error.post_period_start[0].message }}</span>
              </div>
              <div class="form-group select-wrapper per-wrap ml-16">
                <label>{{$t('mediaBuying.campaign.end_of_period')}}</label>
                <i class="far fa-calendar-alt"></i>
                <el-date-picker
                  class="date-picker datepicker-input m-0 p-0 w-100"
                  ref="datepicker"
                  v-model="form.post_period_end"
                  :placeholder="$t('mediaBuying.campaign.specify_period')"
                  type="datetime"
                  format="dd MMM yyyy HH:mm"
                  default-time="23:59:59"
                  @change="error.post_period_end = ''"
                />
                <span
                  v-if="error.post_period_end"
                  class="text-danger text-left mt-2"
                >{{ error.post_period_end[0].message }}</span>
              </div>
            </div>
            <div class="wrap-row-column d-flex mb-8">
              <div v-if="currencyList.length" class="select-wrapper w-100">
                <label>{{$t('dashboard.currency')}}</label>
                <v-select
                  :id="`${$randomizer()}GetLinks.currency_code${form.currency_code}`"
                  v-model="form.currency_code"
                  :options="currencyList"
                  class="el-input"
                  @change="() => {
                    balanceBeforeEdit = 0;
                    form.amount = 0
                  }"
                />
                <span
                  v-if="error.currency_code"
                  class="text-danger text-left mt-2"
                >{{ error.currency_code[0].message }}</span>
                <!-- @change="changeCurrencyCode" -->
              </div>
              <div class="form-group select-wrapper sum-wrap sum-wrap-amount ml-16">
                <label>{{ $t('mediaBuying.campaign.sum') }}</label>
                <div
                  class="d-flex w-100 sum-wrap-amount__wrap"
                  :class="{'disabled': maxAmout.disabled}"
                >
                  <input
                    v-model="form.amount"
                    placeholder="0.00"
                    autocomplete="off"
                    type="text"
                    class="form-control mt-0"
                    :disabled="maxAmout.disabled"
                    @keydown.enter.prevent
                    @input="error.amount = ''"
                  />
                  <div
                    class="sum-wrap-amount__btn text-green"
                    :class="{'disabled': maxAmout.disabled}"
                    @click="setMaxAmount"
                  >
                    {{$t('offers.cardOffers.max')}}:&nbsp;{{formattedCurrency(maxAmout.amount)}}
                  </div>
                </div>
                <span
                  v-if="error && error.amount"
                  class="text-danger text-left mt-2"
                >
                  {{ error.amount[0].message }}
                </span>
              </div>
            </div>

            <label class="urls-wrap__label mb-0 mt-8">URL</label>
            <div class="urls-wrap-out form-group select-wrapper mb-8 mt-0">
              <div v-for="(url, i) in form.urls" :key="i" class="urls-wrap">
                <input
                  v-model="form.urls[i]"
                  placeholder="URL"
                  autocomplete="off"
                  type="text"
                  class="form-control mt-0 mb-10 w-100"
                  @keydown.enter.prevent
                  @input="
                    error.urls && error.urls[i] ? (error.urls[i] = '') : ''
                  "
                />
                <a href="#" class="btn-cross" @click.prevent="popUrl(i)">×</a>
                <div
                  v-if="error.urls && error.urls[i]"
                  :style="{
                    'margin-top': error.urls && error.urls[i] ? '-7px' : 0,
                  }"
                  class="text-danger text-left mb-10"
                >
                  {{ error.urls[i][0].message }}
                </div>
              </div>
              <!-- иногда перезагружается по клику на кнопку -->
              <button
                class="btn btn-outline-primary btn-sm"
                @click.prevent="addUrl"
              >
                + {{ $t('mediaBuying.campaign.add_another_url') }}
              </button>
            </div>
            <div class="form-group select-wrapper mb-8">
              <label>{{ $t('mediaBuying.campaign.description') }}</label>
              <el-input
                type="textarea"
                maxlength="1024"
                v-model="form.description"
                :autosize="{ minRows: 3, maxRows: 8 }"
                class="no-border form-control-lg textarea-autosize"
                :placeholder="$t('mediaBuying.campaign.description')"
              />
              <span v-if="error.description" class="text-danger text-left mt-2">
                {{ error.description[0].message }}
              </span>
            </div>
            <div class="form-group select-wrapper file-wrap mb-0">
              <label class="file-wrap__label">{{
                $t('mediaBuying.campaign.files')
              }}</label>
              <span class="file-wrap__span">
                {{
                  `${$t('mediaBuying.campaign.up_to')} ${
                    $store.state.mediaBuying.uploadSettingsData
                      .max_files_per_request
                  } ${$t('mediaBuying.campaign.files2')},
                ${$t('mediaBuying.campaign.max')} ${
                    $store.state.mediaBuying.uploadSettingsData
                      .max_upload_bytes /
                    1024 /
                    1024
                  }${$t('mediaBuying.campaign.mb_each')}`
                }}
              </span>
              <el-upload
                :class="{
                  disabled:
                    fileList.length ===
                    $store.state.mediaBuying.uploadSettingsData
                      .max_files_per_request,
                }"
                class="upload-demo"
                drag
                action=""
                :on-remove="handleRemove"
                :on-change="onSuccess"
                :file-list="fileList"
                :auto-upload="false"
                multiple
              >
                <div class="el-upload__text">
                  {{ $t('mediaBuying.campaign.dnd') }}
                  <span class="text-green">{{
                    $t('mediaBuying.campaign.select_file')
                  }}</span>
                </div>
              </el-upload>
              <span
                v-if="errorFileDown.length"
                class="error error-file text-danger text-left mt-2"
              >
                {{ errorFileDown[0] }}
              </span>
            </div>
          </form>
        </div>
      </template>
      <div
        slot="footer"
        class="modal-footer-post text-right d-flex align-items-baseline"
      >
        <a class="btn btn-outline-primary btn-sm" @click="close">
          {{ $t('promoCodes.addPromocodes.cancel') }}
        </a>
        <a
          class="btn btn-outline-primary btn-sm ml-16"
          :disabled="loading"
          @click="requestSend('save')"
        >
          <span v-if="!loading">{{ $t('mediaBuying.campaign.save') }}</span>
          <i v-if="loading" class="fas fa-spinner spinner-hide" />
        </a>
        <a
          class="btn btn-primary btn-sm ml-16"
          :disabled="loading"
          @click="requestSend"
        >
          <span :class="{ 'btn-loading': loading }">{{
            $t('mediaBuying.campaign.save_send')
          }}</span>
          <i v-if="loading" class="fas fa-spinner spinner-hide" />
        </a>
      </div>
    </modal>
  </section>
</template>

<script>
import { Modal } from 'src/components';
import formattedCurrency from 'src/helpers/formattedCurrency.js';
import editMessages from './mixins/editMessages';
import uploadFileDragAndDrop from './mixins/uploadFileDragAndDrop';

export default {
  components: {
    Modal,
  },
  mixins: [editMessages, uploadFileDragAndDrop],
  props: {
    id: {
      type: String,
      default: '',
    },
    isPostView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      balanceBeforeEdit: 0,
      campaign: {},
      formattedCurrency,
      fileListBack: [],
      fileListCommentBack: [],
      saveImageDefaultAdd: [],
      errorFileDown: [],
      saveFlag: false,
      idNeedsDeleted: [],
      fileListDouble: [],
      fileListDoubleComment: [],
      fileList: [],
      fileListComment: [],
      countFile: 0,
      countFileComment: 0,
      comment: '',
      comments: [],
      formComment: {
        content: '',
        file_ids: [],
      },
      fileListBackup: [],
      fileIdsBackup: [],
      active: false,
      form: {
        campaign_id: '',
        type: 'publication',
        post_period_start: '',
        post_period_end: '',
        currency_code: '',
        amount: '',
        available_spend: {},
        // platform_id: 'youtube',
        urls: [''],
        description: '',
        file_ids: [],
      },
      file_ids_comment: [],
      error: [],
      saveCheck: false,
      loading: false,
      amount: '',
    };
  },

  computed: {
    hiddenBlock() {
      return Object.keys(this.campaign.balances.approved).length === 0;
    },

    maxAmout() {
      return {
        amount: this.balancesUnreported[this.form.currency_code.toUpperCase()] + this.balanceBeforeEdit || '0.00',
        code: this.form.currency_code || 'USD',
        disabled: !this.balancesUnreported[this.form.currency_code.toUpperCase()] && !this.form.id,
      };
    },

    balancesUnreported() {
      const av = this.$store.getters.campaignAvailableSpendGetter;
      if (av && Object.keys(av).length && !this.isPostView) {
        return av;
      }
      return this.form.available_spend || {};
    },

    currencyList() {
      if (this.$store.state.misc.currencies.length) {
        return this.$store.state.misc.currencies.map(cur => ({ id: cur.code, text: cur.name })).filter(el => Object.keys(this.balancesUnreported || {}).includes(el.id));
      }

      return [{ id: 'USD', text: 'USD' }];
    },

    placeList() {
      return this.$store.state.misc.postPlatforms.map(e => ({
        id: e.id,
        text: e.name,
      }));
    },
  },
  methods: {
    async getCampaign(id) {
      const { payload } = await this.$api.getCampaign(id);
      this.campaign = payload;
      this.form.available_spend = payload.available_spend;
    },

    setMaxAmount() {
      if (!this.maxAmout.disabled) {
        this.form.amount = this.maxAmout.amount;
      }
    },

    getComments() {
      this.getCommentsPayment(this.form.id);
    },
    save() {
      this.saveFlag = false;
      this.form.post_period_start = this.form.post_period_start
        ? moment(this.form.post_period_start).format('YYYY-MM-DD HH:mm:00')
        : '';
      this.form.post_period_end = this.form.post_period_end
        ? moment(this.form.post_period_end).format('YYYY-MM-DD HH:mm:00')
        : '';

      if (!this.form.id) {
        this.$store
          .dispatch('createPost', {
            ...this.form,
            currency_code: this.form.currency_code,
            // status: this.form.status ? this.form.status : 'draft',
            urls: this.form.urls.filter(e => e !== ''),
            campaign_id: this.$store.state.mediaBuying.campaign.id,
          })
          .then(() => {
            this.$emit('addPost', undefined, 'hard');
            this.close();
            this.$alert.success(
              this.$t('mediaBuying.campaign.post_created_message'),
            );
          })
          .catch((err) => {
            this.error = err.response.data.errors;
            this.fileList = this.$_.cloneDeep(this.fileListBackup);
            this.form.file_ids = this.$_.cloneDeep(this.fileIdsBackup);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$store
          .dispatch('editPost', {
            ...this.form,
            status: 'draft',
            urls: this.form.urls.filter(e  => e !== ''),
          })
          .then(() => {
            this.$emit('addPost', undefined, 'hard');
            this.close();
            this.$alert.success(
              this.$t('mediaBuying.campaign.post_edited_message'),
            );
          })
          .catch((err) => {
            this.error = err.response.data.errors;
            this.fileList = this.$_.cloneDeep(this.fileListBackup);
            this.form.file_ids = this.$_.cloneDeep(this.fileIdsBackup);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    send() {
      this.form.post_period_start = this.form.post_period_start
        ? moment(this.form.post_period_start).format('YYYY-MM-DD HH:mm:00')
        : '';
      this.form.post_period_end = this.form.post_period_end
        ? moment(this.form.post_period_end).format('YYYY-MM-DD HH:mm:00')
        : '';
      if (!this.form.id) {
        this.$store
          .dispatch('createPost', {
            ...this.form,
            status: 'draft',
            urls: this.form.urls.filter(e => e !== ''),
            campaign_id: this.$store.state.mediaBuying.campaign.id,
          })
          .then((res) => {
            this.$store
              .dispatch('postToReview', res.payload.id)
              .then(() => {
                this.$emit('addPost', undefined, 'hard');
                this.close();
                this.$alert.success(
                  this.$t('mediaBuying.campaign.post_save_message'),
                );
              })
              .finally(() => {
                this.loading = false;
              });
          })
          .catch((err) => {
            this.error = err.response.data.errors;
            this.fileList = this.$_.cloneDeep(this.fileListBackup);
            this.form.file_ids = [];
            this.loading = false;
          });
      } else {
        this.$store
          .dispatch('editPost', {
            ...this.form,
            urls: this.form.urls.filter(e => e !== ''),
          }).then((res) => {
            this.$store
              .dispatch('postToReview', res.payload.id)
              .then(() => {
                this.$emit('addPost', undefined, 'hard');
                this.close();
                this.$alert.success(
                  this.$t('mediaBuying.campaign.post_save_message'),
                );
              })
              .finally(() => {
                this.loading = false;
              });
          })
          .catch((err) => {
            this.error = err.response.data.errors;
            this.fileList = this.$_.cloneDeep(this.fileListBackup);
            this.form.file_ids = [];
            this.loading = false;
          });
      }
    },
    postCommentsPayment(id) {
      this.formComment.content = this.comment;
      this.$store
        .dispatch('setPostComments', { id, ...this.formComment })
        .then(() => {
          this.comment = '';
          this.formComment = {
            content: '',
            file_ids: [],
          };
          this.getCommentsPayment(this.form.id);
        })
        .finally(() => {
          this.loading = false;
          this.isDisabledBtnComment = false;
        });
    },
    getCommentsPayment(id) {
      const [sort_column, sort_dir] = ['created_at', 'asc'];
      this.$store
        .dispatch('getPostComment', {
          id,
          sort_column,
          sort_dir,
        })
        .then((res) => {
          this.comments = res.payload;
          setTimeout(() => {
            const over = document.getElementsByClassName('comment-overflow')[0];
            if (over) over.scrollTop = 9999;
          }, 100);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async open(row) {
      this.form.available_spend = this.$_.cloneDeep(row.available_spend);
      this.form.currency_code = this.currencyList.length
        ? this.currencyList[0].id
        : '';
      if (row.id) {
        this.getCampaign(row.campaign_id);
        this.getCommentsPayment(row.id);
        this.form = {
          ...this.form,
          ...this.$_.cloneDeep(row),
          currency_code: row.currency_code,
          // urls: row.urls.length ? row.urls.map(item => item.url) : [],
        };
        this.balanceBeforeEdit = this.$_.cloneDeep(this.form.amount);
        this.idNeedsDeleted = this.$_.cloneDeep(
          this.form.files.map(file => file.id),
        );
        this.form.file_ids = this.$_.cloneDeep(this.idNeedsDeleted);
        this.fileList = row.files.map(e => ({
          status: 'ready',
          name: e.filename,
          id: e.id,
        }));

        !row.urls.length
          ? (this.form.urls = [''])
          : (this.form.urls = row.urls.map(item => item.url));
      }
      this.active = true;
    },
    close() {
      this.active = false;
      this.reset();
    },
    reset() {
      this.resetEdit();
      this.balanceBeforeEdit = 0;
      this.fileList = [];
      this.fileListComment = [];
      this.comment = '';
      this.errorFile = [];
      this.errorFileDown = [];
      this.error = [];
      this.form = {
        campaign_id: '',
        type: 'publication',
        post_period_start: '',
        post_period_end: '',
        amount: '',
        available_spend: {},
        urls: [''],
        description: '',
        file_ids: [],
        currency_code: '',
      };
      this.comments = [];
      this.comment = '';
      this.formComment = {
        content: '',
        file_ids: [],
      };
    },
    formatDate(value) {
      return moment(value).format('DD MMM YYYY HH:mm');
    },
    popUrl(i) {
      this.form.urls.splice(i, 1);
    },
    addUrl() {
      this.form.urls.push('');
    },
    currencyFormatter(code) {
      if (!code) {
        return '';
      }

      const miscCurrencies = this.$store.state.misc.currencies;
      const currency = miscCurrencies.find(
        c => code.toLowerCase() === c.code?.toLowerCase(),
      );

      return currency ? currency.code.toUpperCase() : '';
    },
  },
};
</script>
<style lang="scss">
.bluebox {
  border-radius: 8px;
  background-color: #f4f7fa;
  padding: 8px;
  margin-bottom: 16px;
  &__title {
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  &__text {
    font-size: 13px;
  }
}

.mb_add-post {
  .modal-content {
    width: 600px !important;
  }
  .modal-header {
    .modal-title {
      text-transform: capitalize;
    }
  }
  .sum-wrap-amount {
    // position: relative;
    .sum-wrap-amount__wrap {
      display: flex;

      &.disabled {
        cursor: not-allowed;
        opacity: .5;
      }
      input.form-control {
        border-radius: 8px 0 0 8px !important;
      }
    }
    &__btn {
      display: flex;
      align-items: center;
      padding-right: 16px;
      padding-left: 16px;
      background-color: #f4f7fa;
      border: 1px solid #d9d9d9;
      border-left: none;
      border-radius: 0 8px 8px 0;
      cursor: pointer;
      text-transform: capitalize;

      &.disabled {
        pointer-events: none;
        cursor: not-allowed;
      }
    }

  }

}
</style>
