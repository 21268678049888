export function getCountriesCount({ search }) {
  return this.misc
    .get('countries/count', {
      params: {
        search,
      },
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getGeoData() {
  return this.misc
    .get('visitor/geo-data')
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getCountries({
  sort_column, sort_dir, search, limit, offset,
}) {
  return this.misc
    .get('countries', {
      sort_column,
      sort_dir,
      search,
      limit,
      offset,
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getRecaptchaKey() {
  return this.misc
    .get('recaptcha/public-key')
    .then(res => Promise.resolve(res.data.payload))
    .catch(err => Promise.reject(err));
}

export function getPaymentMethodsCount({ search }) {
  return this.misc
    .get('payment-methods/count', { search })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getTimezonesCount({ search }) {
  return this.misc
    .get('timezones/count', { search })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getTimezones({
  sort_column, sort_dir, search, limit, offset,
}) {
  return this.misc
    .get('timezones', {
      sort_column,
      sort_dir,
      search,
      limit,
      offset,
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getLanguages({
  sort_column, sort_dir, search, limit, offset,
}) {
  return this.misc
    .get('languages', {
      sort_column,
      sort_dir,
      search,
      limit,
      offset,
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

// Media Buying
export function getChannelIcons() {
  return this.misc
    .get('/media-buying/channel-icons')
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getCurrencies({
  sort_column = 'code',
  sort_dir,
  search,
  limit,
  offset,
}) {
  return this.misc
    .get('/currencies', {
      params: {
        sort_column,
        sort_dir,
        search,
        limit,
        offset,
      },
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getPaymentMethodCount({ search }) {
  return this.misc
    .get('/media-buying/payment-methods/count', {
      params: {
        search,
      },
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getPostPlatforms() {
  return this.misc
    .get('/platform-urls')
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getPostPlatformsCount({ search }) {
  return this.misc
    .get('/platform-urls/count', {
      params: {
        search,
      },
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

// Traffic Sources
export function getTagTemplates() {
  return this.misc
    .get('tag-manager-templates')
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getMiscSettings() {
  return this.misc
    .get('settings')
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getMessengers() {
  return this.misc
    .get('messengers')
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getCustomerCurrencies() {
  return this.misc
    .get('customer-currencies')
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

